<div class="card">
  <div class="card-content">
    <form *ngIf="uccForm" [formGroup]="uccForm">
      <div class="row">
        <mat-form-field>
          <mat-select class="col-md-12" placeholder="Filing Type" formControlName="filingType">
            <mat-option *ngFor="let fillingType of filingTypes" [value]="fillingType">
              {{ fillingType }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <input matInput class="col-md-12" placeholder="Filling ID" aria-label="filling-id" formControlName="filingId" />
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field class="full-width" ngxErrors="filingDate">
          <input matInput [matDatepicker]="picker" class="calendar" placeholder="Filing date" formControlName="filingDate" />
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error ngxError="required" when="touched"> Due date is required </mat-error>
        </mat-form-field>
      </div>
      <div class="row">
        <mat-form-field>
          <input matInput class="col-md-12" placeholder="Filing number" aria-label="Filing-number" formControlName="filingNumber" />
        </mat-form-field>
      </div>
      <div class="mb-4 mt-3">
        <span class="">
          <mat-radio-group class="" formControlName="senior">
            <span class="alignment mr-3">Senior ?</span>
            <mat-radio-button [value]="true">Yes, override</mat-radio-button>
            <mat-radio-button [value]="false">No, do not override</mat-radio-button>
            <mat-radio-button [value]="null">Not Applicable</mat-radio-button>
          </mat-radio-group>
        </span>
      </div>
      <div class="d-flex align-items-center">
        <button
          mat-raised-button
          class="btn-w-md btn-w-md mr-4"
          (click)="redirectToList()"
          [ngClass]="{ 'btn-busy': toggleInProgress }"
          [color]="'warn'"
        >
          Cancel
        </button>
        <button
          type="submit"
          (click)="submit()"
          [ngClass]="{ 'btn-busy': toggleInProgress }"
          [disabled]="!uccForm.valid"
          class="btn-w-md btn-w-md submit-btn"
          mat-raised-button
          color="accent"
        >
          <span *ngIf="!uccKey">Add</span>
          <span *ngIf="uccKey">Save</span>
        </button>
      </div>
    </form>
  </div>
</div>
