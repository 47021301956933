import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { isUndefined } from 'util';

import { MonitorService } from '../../../../../shared/monitor.service';
import { AppState } from '../../../../../store/app.reducers';
import { selectSupplierDetails } from '../../../../store/supplier/supplier.actions';
import { UccService } from '../ucc.service';
import { SubscriberComponent } from './../../../../../shared/component-subscriber/subscriber.component';

@Component({
  selector: 'app-ucc-create',
  templateUrl: './ucc-create.component.html',
  styleUrls: ['./ucc-create.component.scss'],
})
export class UccCreateComponent extends SubscriberComponent {
  public uccKey: string;
  public uccForm: FormGroup;
  public filingTypes = ['UCC1', 'UCC3'];

  private supplierKey: string;

  constructor(
    private formBuilder: FormBuilder,
    private uccService: UccService,
    private store: Store<AppState>,
    private router: Router,
    private route: ActivatedRoute,
    private monitorService: MonitorService,
  ) {
    super();
    this.monitorService.setUntracked();
    this.createForm();
    this.setSubscriptions();
  }

  redirectToList(): void {
    this.uccKey
      ? this.router.navigate(['../../list'], { relativeTo: this.route })
      : this.router.navigate(['./../list'], { relativeTo: this.route });
  }

  submit(): void {
    if (!this.uccForm.valid) {
      return;
    }

    const data = this.uccForm.value;
    delete data['false'];

    this.uccKey ? this.updateUcc(data) : this.createUcc(data);
  }

  private createUcc(data: any): void {
    this.subscriptions.push(
      this.uccService.postUcc(this.supplierKey, data).subscribe(
        () => {
          this.redirectToList();
        },
        () => {},
      ),
    );
  }

  private updateUcc(data: any): void {
    this.subscriptions.push(
      this.uccService.updateUcc(this.supplierKey, this.uccKey, data).subscribe(
        () => {
          this.redirectToList();
        },
        () => {},
      ),
    );
  }

  private setSubscriptions(): void {
    this.subscriptions.push(
      this.store.select(selectSupplierDetails).subscribe((supplierData) => {
        if (!isUndefined(supplierData)) {
          this.supplierKey = supplierData.supplierKey;
          this.monitorService.send(this.supplierKey);

          this.subscriptions.push(
            this.route.params.subscribe((params: Params) => {
              this.uccKey = params['id'];

              if (this.uccKey) {
                this.setUccData();
              }
            }),
          );
        }
      }),
    );
  }

  private createForm(): void {
    this.uccForm = this.formBuilder.group({
      filingType: ['UCC1', Validators.required],
      filingNumber: [''],
      filingId: ['', Validators.required],
      senior: [true, Validators.required],
      filingDate: ['', Validators.required],
      false: [false],
    });
  }

  private setUccData(): void {
    this.subscriptions.push(
      this.uccService.getPayabilityUcc(this.supplierKey, this.uccKey).subscribe((data) => {
        this.uccForm.patchValue(data);
        if (!data.senior) {
          this.uccForm.get('false').setValue(true);
          this.uccForm.get('senior').clearValidators();
        }

        this.uccForm.updateValueAndValidity();
      }),
    );
  }
}
