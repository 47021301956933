import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CamelCase, Deserialize, DeserializeKeysFrom, Serialize, SerializeKeysTo } from 'cerialize';
import { BehaviorSubject, combineLatest, Observable, of, throwError as observableThrowError } from 'rxjs';
import { catchError, map, share, switchMap, tap } from 'rxjs/operators';
import { isNullOrUndefined } from 'util';

import { AUTH_HEADER_NAME } from '../../../auth/auth-interceptor';
import { AppConfigService } from '../../../config';
import { ConfigsService } from '../../configs/service/configs.service';
import { PermissionBaseModel, PermissionModel } from '../permission.model';
import { UserModel } from '../user.model';
import { UserPermModel } from '../user-perm.model';
import { USER_PERMISSION } from './../user-permissions.enum';
import { UsersAbstractService } from './users-abstract.service';

@Injectable()
export class UsersService extends UsersAbstractService {
  // Fields
  private usersApiUrl: string;
  private permsApiUrl: string;
  private internalApiUrl: string;
  private subPermsArr: any[];
  private usersAndGroupsSubject = new BehaviorSubject(null);
  private usersSubject = new BehaviorSubject([]);
  private groupsSubject = new BehaviorSubject([]);

  constructor(appConfig: AppConfigService, private httpClient: HttpClient, private configsService: ConfigsService) {
    super();

    this.internalApiUrl = appConfig.env.internalApiUrl;
    this.usersApiUrl = this.internalApiUrl + 'logins';
    this.permsApiUrl = this.internalApiUrl + 'perms';
  }

  get users(): Observable<UserModel[]> {
    return this.usersSubject.asObservable();
  }

  get groups(): Observable<PermissionModel[]> {
    return this.groupsSubject.asObservable();
  }

  getPreparedCombinedList(): Observable<UserModel[] | PermissionModel[]> {
    return this.usersAndGroupsSubject.asObservable().pipe(
      switchMap((data) =>
        data
          ? of(data)
          : combineLatest([this.getUsers(), this.getGroups()]).pipe(
            map(([users, groups]) => this.updateAndMapCombinedDependencies(users, groups)),
            tap((combinedData) => this.usersAndGroupsSubject.next(combinedData)),
          ),
      ),
      share(),
    );
  }

  // Login
  public getLogins(
    search: string,
    permCode: USER_PERMISSION | string,
    filterKey: string = 'permCode'
  ): Observable<UserModel[]> {
    let params = new HttpParams().set(filterKey, permCode);
    if (search) {
      params = params.set('search', search);
    }

    return this.httpClient
      .get<UserModel[]>(this.usersApiUrl, {
        params,
      })
      .pipe(
        map((result) => {
          return (<any>result).results.map((item) => {
            DeserializeKeysFrom(CamelCase);
            return Deserialize(item, UserModel);
          });
        }),
      );
  }

  public getLoginPermissions(loginKey: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.usersApiUrl}/${loginKey}/perms`).pipe(
      map((result) => {
        return result;
      }),
    );
  }

  public getPermissionSubPerms(permCode: string): Observable<any[]> {
    if (!isNullOrUndefined(this.subPermsArr)) {
      return of(this.subPermsArr);
    }
    return this.httpClient.get<any[]>(`${this.permsApiUrl}/${permCode}/sub-perms`).pipe(
      map((result) => {
        this.subPermsArr = result;
        return this.subPermsArr;
      }),
    );
  }

  public getLogin(loginKey: string): Observable<UserModel> {
    const endpointUrl = `${this.usersApiUrl}/${loginKey}`;

    return this.httpClient.get(endpointUrl).pipe(
      map((response) => {
        DeserializeKeysFrom(CamelCase);
        return Deserialize(response, UserModel);
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  public saveLogin(loginKey: string, data: UserModel): Observable<any> {
    const endpointUrl = `${this.usersApiUrl}/${loginKey}`;

    SerializeKeysTo(CamelCase);

    const payload = {
      ...Serialize(data, UserModel),
      firstName: data.firstname,
      lastName: data.lastname,
      firstname: undefined,
      lastname: undefined,
    };
    // sorry for this hack but I don't want to broke other places where we use this model

    return this.httpClient.put<UserModel>(endpointUrl, payload).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  public deleteLogin(loginKey: string): Observable<any> {
    const endpointUrl = `${this.usersApiUrl}/${loginKey}`;

    return this.httpClient.delete(endpointUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  // Users
  public getUsers(params?: any): Observable<UserModel[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('permCode', USER_PERMISSION.INT_LOGIN);

    if (params) {
      Object.keys(params).forEach((key) => {
        queryParams = queryParams.append(key, params[key]);
      });
    }

    return this.httpClient
      .get<UserModel[]>(this.usersApiUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          return (<any>result).results.map((item) => {
            DeserializeKeysFrom(CamelCase);
            return Deserialize(item, UserModel);
          });
        }),
      );
  }

  public getUsersQuery(params?: any): Observable<any> {
    let queryParams = new HttpParams();

    if (params) {
      Object.keys(params).forEach((key) => {
        if (!isNullOrUndefined(params[key]) && params[key] !== '') {
          queryParams = queryParams.append(key, params[key]);
        }
      });
    }

    return this.httpClient
      .get(this.usersApiUrl, {
        params: queryParams,
      })
      .pipe(
        map((data) => {
          return {
            results: (<any>data).results.map((item) => {
              DeserializeKeysFrom(CamelCase);
              return Deserialize(item, UserModel);
            }),
            total: (<any>data).total,
          };
        }),
      );
  }

  public getUser(loginId: string, authToken: string): Observable<UserModel> {
    let params = new HttpParams().set('permCode', USER_PERMISSION.INT_LOGIN);
    params = params.set('search', loginId);

    const headers: { [name: string]: string } = {};
    headers[AUTH_HEADER_NAME] = authToken;

    return this.httpClient
      .get<UserModel>(this.usersApiUrl, {
        params,
        headers: new HttpHeaders(headers),
      })
      .pipe(
        map((result) => {
          return (<any>result).results.map((item) => {
            DeserializeKeysFrom(CamelCase);
            return Deserialize(item, UserModel);
          })[0];
        }),
      );
  }

  // Groups
  public getGroups(): Observable<PermissionModel[]> {
    const groupsRequest = this.httpClient.get<UserPermModel[]>(this.permsApiUrl).pipe(
      map((result) => {
        return result.map((item) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(item, PermissionModel);
        });
      }),
    );

    return combineLatest([groupsRequest, this.configsService.get('task_assignee_allowed_groups')]).pipe(
      map(([all, allowed]) => {
        allowed = allowed.map((item) => item.name);
        all = all.filter((group) => allowed.indexOf(group.permCode) >= 0);
        return all;
      }),
    );
  }

  public getGroupUsers(groupId: string): Observable<UserModel[]> {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('permCode', groupId);

    return this.httpClient
      .get<UserModel[]>(this.usersApiUrl, {
        params: queryParams,
      })
      .pipe(
        map((result) => {
          return (<any>result).results.map((item) => {
            DeserializeKeysFrom(CamelCase);
            return Deserialize(item, UserModel);
          });
        }),
      );
  }

  // Permissions
  public getPermissions(): Observable<PermissionModel[]> {
    return this.httpClient.get<UserPermModel[]>(this.permsApiUrl).pipe(
      map((result) => {
        return result.map((item) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(item, PermissionModel);
        });
      }),
    );
  }

  public addPermission(data: PermissionBaseModel): Observable<any> {
    const endpointUrl = `${this.permsApiUrl}`;

    SerializeKeysTo(CamelCase);

    return this.httpClient.post(endpointUrl, Serialize(data)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        // [SM] Temporary workaround - backend shouldn't return empty response
        if (error && error.status === 201 && error.statusText === 'CREATED') {
          return of({});
        } else {
          return observableThrowError(error);
        }
      }),
    );
  }

  public updatePermission(
    permCode: USER_PERMISSION | string,
    data: { permDesc: string; generalPerm: boolean; specSupPerm: boolean },
  ): Observable<any> {
    const endpointUrl = `${this.permsApiUrl}/${permCode}`;

    SerializeKeysTo(CamelCase);

    return this.httpClient.put(endpointUrl, Serialize(data)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        // [SM] Temporary workaround - backend shouldn't return empty response
        if (error && error.status === 201 && error.statusText === 'CREATED') {
          return of({});
        } else {
          return observableThrowError(error);
        }
      }),
    );
  }

  public removePermission(permCode: USER_PERMISSION | string): Observable<any> {
    const endpointUrl = `${this.permsApiUrl}/${permCode}`;

    return this.httpClient.delete(endpointUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  public checkPermission(permCode: USER_PERMISSION, user: UserModel = null): Observable<boolean> {
    return this.getProvidedPersmissions(user).pipe(
      map((permissions) => {
        return permissions.filter((permission) => permission.permCode === permCode).length > 0 ? true : false;
      }),
    );
  }

  public checkPermissions(permCodes: USER_PERMISSION[], user: UserModel = null): Observable<boolean> {
    return this.getProvidedPersmissions(user).pipe(
      map((permissions) => !!permissions.filter((permission) => permCodes.includes(permission.permCode)).length),
    );
  }

  public checkGeneralPermission(permCode: USER_PERMISSION, user: UserModel = null): Observable<boolean> {
    if (!user) {
      return of(false);
    }

    return of(user.perms[0].permCode === permCode);
  }

  public checkGeneralPermissions(permCodes: USER_PERMISSION[], user: UserModel = null): Observable<boolean> {
    if (!user || !permCodes) {
      return of(false);
    }

    return of(permCodes.includes(user.perms[0].permCode as USER_PERMISSION));
  }

  public getSubpermissions(permCode: USER_PERMISSION | string): Observable<PermissionModel[]> {
    const endpointUrl = `${this.permsApiUrl}/${permCode}/sub-perms`;

    return this.httpClient.get<PermissionModel[]>(endpointUrl).pipe(
      map((result) => {
        return result.map((item) => {
          DeserializeKeysFrom(CamelCase);
          return Deserialize(item, PermissionModel);
        });
      }),
    );
  }

  public addSubpermission(permCode: USER_PERMISSION | string, data: { permCode: USER_PERMISSION | string }): Observable<any> {
    const endpointUrl = `${this.permsApiUrl}/${permCode}/sub-perms`;

    return this.httpClient.post(endpointUrl, Serialize(data)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        // [SM] Temporary workaround - backend shouldn't return empty response
        if (error && error.status === 201 && error.statusText === 'CREATED') {
          return of({});
        } else {
          return observableThrowError(error);
        }
      }),
    );
  }

  public removeSubpermission(permCode: USER_PERMISSION | string, subPermCode: string): Observable<any> {
    const endpointUrl = `${this.permsApiUrl}/${permCode}/sub-perms/${subPermCode}`;

    return this.httpClient.delete(endpointUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  public addPermissionToLogin(loginKey: string, data: { permCode: USER_PERMISSION | string; supplierKey: string }): Observable<any> {
    const endpointUrl = `${this.usersApiUrl}/${loginKey}/perms`;

    SerializeKeysTo(CamelCase);

    return this.httpClient.post(endpointUrl, Serialize(data)).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  public removePermissionFromLogin(loginKey: string, loginPermKey: string): Observable<any> {
    const endpointUrl = `${this.usersApiUrl}/${loginKey}/perms/${loginPermKey}`;

    return this.httpClient.delete(endpointUrl).pipe(
      map((response) => {
        return response;
      }),
      catchError((error) => {
        return observableThrowError(error);
      }),
    );
  }

  private getProvidedPersmissions(user: UserModel = null): Observable<any> {
    if (!user) {
      user = JSON.parse(localStorage.getItem('currentUser')) as UserModel;
    }

    if (user && user.hasOwnProperty('perms') && user.perms.length > 0) {
      return this.getPermissionSubPerms(user.perms[0].permCode);
    } else {
      return of([]);
    }
  }

  private updateAndMapCombinedDependencies(users: UserModel[], groups: PermissionModel[]): (UserModel | PermissionModel)[] {
    this.usersSubject.next(users);
    this.groupsSubject.next(groups);
    return [...groups, ...users].sort(this.compareNames);
  }
}
